import * as React from "react"

import Layout from "components/Layout"
import Seo from "components/SEO"
import ContactBanner from "components/ContactBanner"
import HeroBanner from "components/HeroBanner"
import InfoBlocksSection from "components/InfoBlocksSection"
import MainInfoSection from "components/MainInfoSection"

import { LandingPageType } from "types/enums"

const ManagementCompaniesPage = () => (
  <Layout landingType={LandingPageType.MANAGEMENT_COMPANIES}>
    <Seo title="For Management Companies" />
    <HeroBanner landingType={LandingPageType.MANAGEMENT_COMPANIES} />
    <InfoBlocksSection landingType={LandingPageType.MANAGEMENT_COMPANIES} />
    <MainInfoSection landingType={LandingPageType.MANAGEMENT_COMPANIES} />
    <ContactBanner landingType={LandingPageType.MANAGEMENT_COMPANIES} />
  </Layout>
)

export default ManagementCompaniesPage
